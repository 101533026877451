import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { noop } from 'lodash';
import React, { createContext, useEffect, useState } from "react";

import { silentAuth } from "../services/auth";

export const state = createContext();

const stripePromise = loadStripe(process.env.STRIPE_TEST_KEY);

const isBrowser = typeof window !== "undefined";

let isMounted = false;

function StateProvider(props) {
  const [isDark, setTheme] = useState(false);
  const [isClassyModalOpen, setIsClassyModalOpen] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      if (isBrowser) {
        const persistedTheme = localStorage.getItem("theme");
        setTheme(persistedTheme === "dark");
      }
      isMounted = true;
    }
  });

  return (
    <state.Provider
      value={{
        isDark,
        changeTheme: () => {
          localStorage.setItem('theme', isDark ? 'light' : 'dark')
          setTheme(!isDark);
        },
        isClassyModalOpen,
        toggleClassyModal: () => setIsClassyModalOpen(!isClassyModalOpen),
        stripe: (cb = noop) => stripePromise.then(cb),
      }}
    >
      {props.children}
    </state.Provider>
  );
}

class SessionCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  handleCheckSession = () => {
    this.setState({ loading: false });
  };

  componentDidMount() {
    silentAuth(this.handleCheckSession);
  }

  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    );
  }
}

const StripeProvider = ({ children }) => {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default function Provider({ element }) {
  return (
    <SessionCheck>
      <StateProvider>
        <StripeProvider>
          {element}
        </StripeProvider>
      </StateProvider>
    </SessionCheck>
  );
}
