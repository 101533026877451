// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-classy-js": () => import("./../../../src/pages/app/classy.js" /* webpackChunkName: "component---src-pages-app-classy-js" */),
  "component---src-pages-app-edit-profile-js": () => import("./../../../src/pages/app/edit-profile.js" /* webpackChunkName: "component---src-pages-app-edit-profile-js" */),
  "component---src-pages-app-following-js": () => import("./../../../src/pages/app/following.js" /* webpackChunkName: "component---src-pages-app-following-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-app-library-js": () => import("./../../../src/pages/app/library.js" /* webpackChunkName: "component---src-pages-app-library-js" */),
  "component---src-pages-app-roadmap-js": () => import("./../../../src/pages/app/roadmap.js" /* webpackChunkName: "component---src-pages-app-roadmap-js" */),
  "component---src-pages-app-setup-profile-js": () => import("./../../../src/pages/app/setup-profile.js" /* webpackChunkName: "component---src-pages-app-setup-profile-js" */),
  "component---src-pages-app-subscribed-js": () => import("./../../../src/pages/app/subscribed.js" /* webpackChunkName: "component---src-pages-app-subscribed-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

