import auth0 from "auth0-js";
import { navigate } from "gatsby";
import { noop } from "lodash";

const isBrowser = typeof window !== "undefined";

const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.AUTH0_DOMAIN,
      clientID: process.env.AUTH0_CLIENT_ID,
      redirectUri: `${window.location.origin}/callback`,
      responseType: "token id_token",
      scope: "openid profile email",
    })
  : {};

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
};

export const isAuthenticated = () => {
  if (!isBrowser) {
    return;
  }

  return localStorage.getItem("isLoggedIn") === "true";
};

export const login = () => {
  if (!isBrowser) {
    return;
  }

  auth.authorize();
};

const setSession =
  (cb = noop) =>
  (err, authResult) => {
    if (err) {
      navigate("/");
      cb();
      return;
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
      let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
      tokens.accessToken = authResult.accessToken;
      tokens.idToken = authResult.idToken;
      tokens.expiresAt = expiresAt;
      const user = authResult.idTokenPayload;
      localStorage.setItem("user", JSON.stringify({ ...getUser(), ...user }));
      localStorage.setItem("isLoggedIn", true);
      cb({ user });
    }
  };

export const handleAuthentication = async (callback) => {
  if (!isBrowser) {
    return;
  }

  auth.parseHash(setSession(callback));
};

const getUser = () => {
  if (!isBrowser) {
    return;
  }

  return JSON.parse(localStorage.getItem("user"));
};

export const getProfile = () => {
  return getUser() || {};
};

export const setProfile = (profile) => {
  if (!isBrowser) {
    return;
  }

  localStorage.setItem(
    "user",
    JSON.stringify({
      ...getUser(),
      ...profile,
    })
  );
  return getUser();
};

export const silentAuth = (callback) => {
  if (!isAuthenticated()) return callback();
  auth.checkSession({}, setSession(callback));
};

export const logout = () => {
  if (!isBrowser) {
    return;
  }

  localStorage.setItem("isLoggedIn", false);
  auth.logout({
    returnTo: window.location.origin,
  });
};
