module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://periodical.hasura.app/v1/graphql","headers":{"x-hasura-admin-secret":"IU0DE0eywIeFSehM1beBfT6FD2i3VssHlNif5ARp1Zx7Baygjzci9rBM0JRDfCPd"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Periodical","short_name":"Periodical","start_url":"/","background_color":"#333333","theme_color":"#000000","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aff11c09f5b6038e743f778b35f36e4a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
